@import "./colors.module";

.profileContainer {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  // background-color: $normalBlue;
  display:flex;
  align-items: center;
  padding-top:40px;
  padding-bottom:20px;
  // justify-content: center;
  flex-direction: column;
  & h3 {
    margin-top:0px;
  }
  & .imgBox {
    width:100px;
    height:100px;
    border-radius: 50px;
    border:1px solid white;
    position: relative;
    & img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  & .profileBox {
    margin-top:20px;
    padding:20px;
    width:80%;
    max-width: 540px;
    border-radius: 10px;
    background-color:white;
    & h1 {
      text-align:center;
      font-weight: bold;
      font-size:1.5rem;
    }
    & h2 {
      font-weight: bold;
      color:$textGrey;
      margin-top:20px;
      margin-bottom: 10px;
    }
    & h3 {
      font-weight: bold;
      font-size:1.5rem;
      margin-left:10px;
      margin-bottom: 0px;
    }
    
  }
}

@media (max-width: 560px) {
  .profileBox {
    width:90%!important;
  }
}


