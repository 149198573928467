@import "./styles/colors.module";

body {
  margin: 0;
  font-family: "basic-sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

label.customCheckbox {
  cursor:pointer;
}

div.customCheckbox {
  width:100%;
  height:100%;
  transition:all 0.2s ease;
  --background-color:#4361ee;
  &.checked {
    background-color:var(--background-color);
  }
}

.save-btn {
  outline:none;
  width:100%;
  padding:12px 20px;
  border-radius: 8px;
  color:white;
  font-size:18px;
  font-weight: bold;
  font-family: "basic-sans";
  background-color:$normalBlue;
  margin-top:20px;
  border:none;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  &:hover {
    background-color:$darkBlue;
    color:white;
  }
  &:disabled {
    background-color:$lightBlue;
    cursor:default;
  }
}

.preferences {
  display:flex;
  flex-direction: row;
  align-items:center;
  flex-wrap: wrap;
  width:100%;
  margin-bottom: 10px;
  & .onePref {
    padding:10px 15px;
    background-color:white;
    border:1px solid $normalBlue;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-right: 10px;
    
    margin-bottom: 10px;
    &:hover {
      transform:translateY(-3px)
    }
    &.active {
      background-color:$normalBlue;
      & span {
        color:white;
      }
    }
    & span {
      color:$normalBlue;
    }
  }
}
