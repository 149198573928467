$normalBlue:#4361ee;
$lightBlue:#8294e5;
$darkBlue:#3a0ca3;
$turkis:#4cc9f0;
$textGrey:rgb(92, 92, 92);
$lightGrey:#e8ebed;
$errorRed:#ea6363;
$darkRed:#c64f4f;
$lightRed:#f99696;
$dividerGrey:rgb(236, 236, 236);
