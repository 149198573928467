@import "./colors.module";

// input fields
.inputWrapper {
  position:relative;
  width:100%;
  margin-top: 20px;
  &:first-of-type{
    margin-top: 0px;
  }
  & .info {
    display:flex;
    align-items: center;
    margin-bottom: 15px;
    & i {
      font-size:28px;
      color:$normalBlue;
    }
    & span {
      margin-left: 10px;

    }
  }
  
  & .inputBox {
    position: relative;
    width: 100%;
    min-height:3.2rem;
    --background-color:#4361ee;
    & .areaCount{
      position: absolute;
      bottom:5px;
      right:10px;
      display:flex;
      align-items: center;
      & span {
        color:grey;
      }
    }
    & .input {
      position: absolute;
      box-sizing: border-box;
      top: 0;
      left:0;
      width: 100%;
      height: 100%;
      border:1px solid #b1b1b1;
      border-radius:8px;
      font-family: inherit;
      font-size:16px;
      outline:none;
      padding:1.5rem 1.25rem;
      &:hover:not(:disabled),&:focus {
        border-color:var(--background-color)
      }
    }
    
    & .label {
      position:absolute;
      background-color:white;
      left:0.7rem;
      top:0.9rem;
      font-size:16px;
      padding: 0 0.5rem;
      cursor:text;
      transition:all 200ms ease-in;
      color:rgb(121, 121, 121);
    }
    & .input:disabled ~ .label {
      background-color:transparent;
    }
    & .input:focus ~ .label, .input:not(:placeholder-shown).input:not(:focus) ~ .label {
      top:-0.5rem;
      font-size:14px;
      left:0.7rem;
      color:var(--background-color);
    }
    & .input:focus, .input:not(:placeholder-shown).input:not(:focus){
      border-color: var(--background-color);
    }
  }
}

.fieldBtn {
  margin-top:10px;
  border:none;
  background-color:$normalBlue;
  border-radius: 7px;
  padding:10px 20px;
  color:white;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color:$darkBlue;
  }
}